import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    [theme.breakpoints.up("md")]: {
      //border: '0.2px solid #203864',
    }
  },
  gridList: {
    width: '100%',
    height: '100%',
  },
  item: {
    //border: '0.2px solid #203864',
    textAlign: 'center',
    height: '100%',
    width: '100%',
  },
  itemContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    lineHeight: '1rem',
  },
  image: {
    height: '100%',
    width: '100%',
  }
}));

export default useStyles