import React from "react"
import useStyles from './styles'
import { Link } from "gatsby"


const ChallengeItem = ({ item }) => {
  const classes = useStyles()

  const image = item?.img.childImageSharp.fluid.originalImg
  const detailImage = item?.detailImg.childImageSharp.fluid.originalImg
  const id = item?.id
  const description = item?.description
  const title = item?.title
  const subtitle = item?.subtitle
  return (
    <>
      {detailImage ?
        <Link to={`/digitalchallenge-item-page?id=${id}`} className={classes.link}>
          <div className={classes.itemContent}>
            <div>
              <img src={image} alt={subtitle} title={title} className={classes.image} />
            </div>
          </div>
        </Link>
        :
        <div className={classes.itemContent}>
          <div>
          <img src={image} alt={subtitle} title={title} className={classes.image} />
          </div>
        </div>
      }
    </>
  )
}

export default ChallengeItem