import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import useStyles from '../styles/challengeStyles'
import { GridList, GridListTile, Hidden, Grid } from '@material-ui/core'
import ChallengeItem from '../components/ChallengeItem'
import PageTitle from '../components/PageTitle'
import SEO from '../components/seo'
//im portfolio muss die summe der cols immer 12 ergeben. die reihenfolge der objekte bestimmt die abfolge in der darstellung - nicht die id. wenn eine description vorhanden ist, wird der grid auf die portfolioItem seite verlinkt.
const Portfolio = () => {

  const classes = useStyles()
  const query = useStaticQuery(graphql`
{
  allFile(filter: {childMarkdownRemark: {frontmatter: {id: {eq: "digitalchallenges"}}}}) {
    nodes {
      childMarkdownRemark {
        frontmatter {
          title
          seoDescription
          seoKeywords
          seoTitle
          challenges {
            img {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
              }
            }, 
            detailImg {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
              }
            }, 
            id
            subtitle
            title
            cols
          }
        }
        html
      }
    }
  }
}
`)

  const challengeItems = query.allFile.nodes[0].childMarkdownRemark.frontmatter.challenges
  const pageTitle = query.allFile.nodes[0].childMarkdownRemark.frontmatter.title
  const seoDescription = query?.allFile.nodes[0].childMarkdownRemark.frontmatter.seoDescription
  const seoKeywords = query?.allFile.nodes[0].childMarkdownRemark.frontmatter.seoKeywords
  const seoTitle = query?.allFile.nodes[0].childMarkdownRemark.frontmatter.seoTitle
  const markdown = query.allFile.nodes[0].childMarkdownRemark.html

  return (
    <Layout>
      <SEO title={seoTitle !== undefined ? seoTitle : pageTitle} description={seoDescription !== undefined ? seoDescription : null} keywords={seoKeywords !== undefined ? seoKeywords : null}/>
      <div className={classes.root}>
        <Hidden smDown>
          <div className={classes.main} dangerouslySetInnerHTML={{ __html: markdown }} />
          <GridList cellHeight='auto' className={classes.gridList} cols={12} >
            {challengeItems.map((item) => (
              <GridListTile key={item.id} cols={item.cols} className={classes.item}>
                <ChallengeItem item={item} />
              </GridListTile>
            ))}
          </GridList>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.main} dangerouslySetInnerHTML={{ __html: markdown }} />
          <Grid container spacing={4}>
            {challengeItems.map((item) => (
              <Grid item xs={12} sm={12} key={item.id} >
                <div className={classes.item}>
                  <ChallengeItem item={item} />
                </div>
              </Grid>
            ))}
          </Grid>
        </Hidden>

      </div>
    </Layout>
  )
}
export default Portfolio
