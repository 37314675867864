import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  itemContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& h3':{
      fontSize:'1.1rem',
      color: theme.palette.primary.main,
    },
    '& p':{
      fontSize:'1rem',
      color: theme.palette.primary.menu,
      lineHeight:'1.4rem'
    }
  },
  image: {
    height: '100%',
    width: '100%',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.menu,
  }
}));

export default useStyles